<template>
  <div class="container user-setting-market">
    <div class="page-header mb-1">
      <strong>เปิด/ปิด รายการหวย</strong>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" href="#" target="_self" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</a>
          </li>
        </ul>
      </div>
      <div class="p-1 mt-2">
        <button v-for="market in marketItems" :class="[{'btn-primary':selectedMarketId===market._id}, {'btn-light':selectedMarketId!==market._id}]" class="btn btn-sm m-1" @click="selectedMarketId=market._id">{{market.marketTitle}}</button>
      </div>
      <div class="p-2">
        <table class="table-accounts table table-hover mb-0">
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>ชื่อใช้งาน</th>
              <th>ระดับ</th>
              <th>ตั้งค่า</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in accountItems" :key="item._id" :class="[{'alert-dark': !item.groupAvailable}]">
              <td>{{ index+1 }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.levelName }}</td>
              <td>
                <b-form-checkbox
                  v-model="item.isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(item)"
                  :class="[item.isAvailable && item.groupAvailable ? 'text-success' : 'text-danger']"
                  :disabled="!item.groupAvailable"
                >
                  {{ item.isAvailable && item.groupAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
            </tr>
            <tr v-if="!accountItems.length">
              <td class="text-center" colspan="4">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from "@/services/accountService"
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'

export default {
  name: 'SettingMarket',
  data() {
    return {
      tabActiveGroupId: null,
      accounts: [],
      markets: [],
      selectedMarketId: null
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.filter(group=>group.status==='Active' && group.groupType==='single')
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    marketItems() {
      return this.markets.filter(market=>market.groupId===this.tabActiveGroupId)
    },
    accountItems() {
      if(this.selectedMarketId) {
        return this.accounts.map((acc)=>{
          return {
            _id: acc._id,
            username: acc.user.username,
            levelName: acc.level.levelName,
            groupId: this.tabActiveGroupId,
            marketId: this.selectedMarketId,
            isAvailable: acc?.markets?.[this.selectedMarketId]?.isAvailable === false ? false : true,
            groupAvailable: acc?.groups[this.tabActiveGroupId]?.isAvailable || false
          }
        })
      }else{
        return []
      }
    }
  },
  watch: {
    marketItems() {
      if(this.marketItems.length) {
        this.selectedMarketId = this.marketItems[0]?._id
      }
    }
  },
  methods: {
    loadAccounts() {
      AccountService.getAccountSettingMarkets()
      .then((response)=>{
        if(response.success) {
          this.accounts = response.data
          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id
          }
        }
      })
    },
    loadMarkets() {
      MarketService.getMarkets()
      .then((response)=>{
        if(response.success) {
          this.markets = response.data
        }
      })
    },
    toggleChange(account) {
      console.log(account.isAvailable)
      this.save({
        accountIds: [account._id],
        marketIds: [account.marketId],
        isAvailable: account.isAvailable
      })
    },
    save(data) {
      AccountService.saveAccountSettingMarkets(data)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        console.log(e.message)
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    }
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  },
  mounted() {
    this.loadAccounts()
    this.loadMarkets()
  }
}
</script>
<style lang="scss" scoped>
.user-setting-market {
  table {
    th {
      border-top: 0;
    }
    td {
      vertical-align: middle;
    }
  }
}
</style>
